import React, { useEffect, useRef } from 'react';
import { fabric } from 'fabric';

const CanvasComp = () => {
  const canvasRef = useRef(null);
  const CANVAS_WIDTH = 1000; // Full canvas width
  const CANVAS_HEIGHT = 1000; // Full canvas height
  const WORKSPACE_WIDTH = 600; // White workspace width
  const WORKSPACE_HEIGHT = 600; // White workspace height
  const WORKSPACE_COLOR = 'white'; // Workspace color

  useEffect(() => {
    const canvas = new fabric.Canvas(canvasRef.current, {
      width: window.innerWidth,
      height: window.innerHeight,
      backgroundColor: '#F0F0F0', // Light grey background for outside workspace
    });

    // Define the workspace as a white rectangle in the center
    const workspaceRect = new fabric.Rect({
      left: 0,
      top: 0,
      width: WORKSPACE_WIDTH,
      height: WORKSPACE_HEIGHT,
      fill: WORKSPACE_COLOR,
      // originX:'center',
      // originY:'center',
      selectable: false, // Ensure this cannot be selected
    });

    // Add workspace to canvas
    canvas.add(workspaceRect);

    // Create a draggable object example
    const rect = new fabric.Rect({
      left: 150,
      top: 150,
      fill: 'red',
      width: 50,
      height: 50,
    });

    // Add the object to canvas
    canvas.add(rect);
    let isDragging = false;
    let lastPosX = 0;
    let lastPosY = 0;
    const handleMouseDown = (opt) => {
      const evt = opt.e;       

      if (evt.altKey === true) {
        isDragging = true;
        // Disable selection if alt key is pressed
        canvas.selection = false; // Update with the correct canvas instance if needed
        lastPosX = evt.clientX;
        lastPosY = evt.clientY;
      }
    };
    canvas.on('mouse:down', handleMouseDown);

    // Ensure objects are still interactable outside the workspace
    canvas.on('object:moving', (e) => {
      const obj = e.target;

      // Ensure the object doesn't move outside the full canvas area
      if (obj.left < 0) obj.left = 0;
      if (obj.top < 0) obj.top = 0;
      if (obj.left + obj.width > CANVAS_WIDTH) obj.left = CANVAS_WIDTH - obj.width;
      if (obj.top + obj.height > CANVAS_HEIGHT) obj.top = CANVAS_HEIGHT - obj.height;

      canvas.renderAll();
    });

    return () => {
      canvas.dispose(); // Clean up canvas on component unmount
    };
  }, []);

  return (
    <div>
      <canvas ref={canvasRef} />
    </div>
  );
};

export default CanvasComp;
