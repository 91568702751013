import React, { useState } from "react";
import {
  IconButton,
  Typography,
  Checkbox,
  FormControlLabel,
  FormControl,
  Select,
  MenuItem,
  Button,
  TextField,
} from "@mui/material";
import { ArrowBack, Clear } from "@mui/icons-material";

const CustomRotateCopiesPopoverContent = ({
  handlePopoverClose,
  rotationSettings,
  handleBackButtonClick,
  handlePathRotateCopies,
  updateRotationSettings,
  setSelectedColorForRotationCopy,
  selectedColorForRotationCopy,
  selectedStrokeForRotationCopy,
  setSelectedStrokeForRotationCopy
}) => {
  const handleToggleChange = (key, value) => {
    updateRotationSettings(key, value);
  };

  return (
    <div
    style={{ height: "500px", width: '300px' }}
    >
      <div style={{ display: "flex", justifyContent: 'space-between', alignItems: "center", margin:"0px 10px" }}>
      <IconButton edge="start" sx={{ borderRadius: 0 }} onClick={handleBackButtonClick}>
                <ArrowBack />
              </IconButton>
        <Typography sx={{ fontSize: 18, fontWeight: 800 }}>
          Rotation Copies
        </Typography>
        <IconButton
          edge="end"
          onClick={handlePopoverClose}
          sx={{ color: "#f0f2f7" }}
        >
          <Clear />
        </IconButton>
      </div>
      <div style={{ display: "flex", rowGap: 18, margin: "10px",paddingBottom:'10px', flexWrap: "wrap", justifyContent:"center" }}>

      <div style={{display:'flex',width:'100%',alignItems:'center', justifyContent:"space-between"}}>

        <label
          style={{
            fontSize: "14px",
            marginBottom: "5px",
            alignContent: "center",
            width: "50%", // Ensuring label and form control are aligned
          }}
        >
          Method:
        </label>
        <FormControl
          size="small"
          variant="outlined"
          sx={{ backgroundColor: "#f0f2f7", borderRadius: "4px", width: "100%" }} // Adjust width to match the label
        >
          <Select
            value={rotationSettings.method}
            sx={{ color: "black" }}
            onChange={(e) => handleToggleChange("method", e.target.value)}
          >
            <MenuItem value="Normal">Normal</MenuItem>
            <MenuItem value="Kaleidoscope">Kaleidoscope</MenuItem>
            <MenuItem value="Fuse Paths">Fuse Paths</MenuItem>
          </Select>
        </FormControl>
      </div>

      <div style={{display:'flex',width:'100%',alignItems:'center', justifyContent:"space-between"}}>

        <label
          style={{
            fontSize: "14px",
            marginBottom: "5px",
            alignContent: "center",
            width: "50%", // Ensuring label and input field are aligned
          }}
        >
          Number of Copies:
        </label>
        <input
          type="number"
          value={rotationSettings.numCopies}
          style={{
            width: "50%", // Matching width with the label and dropdown
            padding: "5px",
            borderRadius: "4px",
            border: "1px solid #ccc",
          }}
          onChange={(e) => handleToggleChange("numCopies", e.target.value)}
        />
      </div>

      <div style={{display:'flex',width:'100%',alignItems:'center', justifyContent:"space-between"}}>

        <label
          style={{
            display: "flex",
            justifyContent: "space-between",
            fontSize: "14px",
          }}
        >
          Starting Angle:
          <input
            type="number"
            value={rotationSettings.startingAngle}
            style={{
              width: "50%",
              padding: "5px",
              borderRadius: "4px",
              border: "1px solid #ccc",
            }}
            onChange={(e) =>
              handleToggleChange("startingAngle", e.target.value)
            }
          />
        </label>
      </div>

      <div style={{display:'flex',width:'100%',alignItems:'center', justifyContent:"space-between"}}>

        <label
          style={{
            display: "flex",
            justifyContent: "space-between",
            fontSize: "14px",
          }}
        >
          Rotation Angle:
          <input
            type="number"
            value={rotationSettings.rotationAngle}
            style={{
              width: "50%",
              padding: "5px",
              borderRadius: "4px",
              border: "1px solid #ccc",
            }}
            onChange={(e) =>
              handleToggleChange("rotationAngle", e.target.value)
            }
          />
        </label>
      </div>

      <div style={{display:'flex',width:'100%',alignItems:'center', justifyContent:"space-between"}}>

        <label
          style={{
            display: "flex",
            justifyContent: "space-between",
            fontSize: "14px",
          }}
        >
          Gap:
          </label>

          <input
            type="number"
            value={rotationSettings.gap}
            style={{
              width: "50%",
              padding: "5px",
              borderRadius: "4px",
              border: "1px solid #ccc",
            }}
            onChange={(e) => handleToggleChange("gap", e.target.value)}
          />
      </div>
      <div style={{display:'flex',width:'100%',alignItems:'center', justifyContent:"space-between"}}>
        <Typography
          style={{ fontSize: "14px", fontWeight: 600}}
        >
          Origin
        </Typography>
      </div>
      <div>
        <div
          style={{
            marginBottom: "10px",
            display: "grid",
            gridTemplateColumns: "1fr 1fr", // Two columns
            alignItems: "center", // Align items in the center vertically
          }}
        >
          <label style={{ fontSize: "14px" }}>
            X:
            <input
              type="number"
              value={rotationSettings.originX}
              style={{
                width: "100px",
                marginLeft: "5px",
                padding: "5px",
                borderRadius: "4px",
                border: "1px solid #ccc",
              }}
              onChange={(e) => handleToggleChange("originX", e.target.value)}
            />
          </label>
          <label style={{ fontSize: "14px" }}>
            Y:
            <input
              type="number"
              value={rotationSettings.originY}
              style={{
                width: "100px",
                marginLeft: "5px",
                padding: "5px",
                borderRadius: "4px",
                border: "1px solid #ccc",
              }}
              onChange={(e) => handleToggleChange("originY", e.target.value)}
            />
          </label>
        </div>
      </div>

      <div
        style={{
          marginBottom: "10px",
          marginTop: "20px",
          display: "grid",
          gridTemplateColumns: "1fr 1fr", // Two columns
          alignItems: "center", // Align items in the center vertically
        }}
      >
        <FormControlLabel
          control={
            <Checkbox
              checked={rotationSettings.distributeEvenly}
              onChange={(e) =>
                handleToggleChange("distributeEvenly", e.target.checked)
              }
            />
          }
          label="Distribute Evenly"
          sx={{ color: "#f0f2f7", fontSize: "14px" }}
        />
        <FormControlLabel
          control={
            <Checkbox
              // value={rotationSettings.mirrorCopies}
              checked={rotationSettings.mirrorCopies}
              onChange={(e) =>
                handleToggleChange("mirrorCopies", e.target.checked)
              }
            />
          }
          label="Mirror Copies"
          sx={{ color: "#f0f2f7", fontSize: "14px" }}
        />
        <FormControlLabel
          control={
            <Checkbox
              // value={rotationSettings.splitElements}
              checked={rotationSettings.splitElements}
              onChange={(e) =>
                handleToggleChange("splitElements", e.target.checked)
              }
            />
          }
          label="Split Elements"
          sx={{ color: "#f0f2f7", fontSize: "14px" }}
        />
        <FormControlLabel
          control={
            <Checkbox
              //   value={rotationSettings.linkStyles}
              checked={rotationSettings.linkStyles}
              onChange={(e) => {
                console.log("mirrorCopies", e);
                handleToggleChange("linkStyles", e.target.checked);
              }}
            />
          }
          label="Link Styles"
          sx={{ color: "#f0f2f7", fontSize: "14px" }}
        />
      </div>
      {rotationSettings.splitElements || rotationSettings.linkStyles ? (
        <>
        <TextField
          label="Fill Color"
          type="color"
          value={selectedColorForRotationCopy}
          onChange={
            (e) => {
              setSelectedColorForRotationCopy(e.target.value);
            }
            // handlePositionChange('fill', e.target.value)
          }
          fullWidth
          margin="normal"
        />
        <TextField
          label="Stroke Color"
          type="color"
          value={selectedStrokeForRotationCopy}
          onChange={
            (e) => {
              setSelectedStrokeForRotationCopy(e.target.value);
            }
            // handlePositionChange('fill', e.target.value)
          }
          fullWidth
          margin="normal"
        />
        </>
      ) : (
        <></>
      )}

      <div
        style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={handlePathRotateCopies}
          sx={{
            backgroundColor: "#5C6BC0",
            color: "#fff",
            padding: "10px 20px",
          }}
        >
          Apply Rotation
        </Button>
      </div>
    </div>
    </div>
  );
};

export default CustomRotateCopiesPopoverContent;
