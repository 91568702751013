import { fabric } from 'fabric';

export class ElbowConnectorLine {
    constructor(canvas, options) {
        this.canvas = canvas;

        // Create start and end control points
        this.startPoint = new fabric.Circle({
            left: options.startX,
            top: options.startY,
            radius: 5,
            fill: 'blue',
            hasControls: false,
            hasBorders: false,
            lockRotation: true,
            visible:false,
            lockScalingX: true,
            lockScalingY: true,
            originX: 'center',
            originY: 'center'
        });

        this.endPoint = new fabric.Circle({
            left: options.endX,
            top: options.endY,
            radius: 5,
            fill: 'blue',
            visible:false,
            hasControls: false,
            hasBorders: false,
            lockRotation: true,
            lockScalingX: true,
            lockScalingY: true,
            originX: 'center',
            originY: 'center'
        });

        // Create and add the initial path
        this._createPath(options);

        // Add objects to the canvas
        this.canvas.add(this.path, this.startPoint, this.endPoint);

        this._bindSelectionEvents();
        // Bind moving events
        this._bindEvents();
    }

    _bindEvents() {
        this.startPoint.on('moving', () => this._updatePath());
        this.endPoint.on('moving', () => this._updatePath());
        this.path.on('moving', () => this._updateControlPoints());
    }
    _bindSelectionEvents() {
        this.path.on('selected', () => this._showControlPoints());
        this.path.on('deselected', () => this._hideControlPoints());
    }

    _showControlPoints() {
        this.startPoint.set({ visible: true });
        this.endPoint.set({ visible: true });
        this.canvas.renderAll();
    }

    _hideControlPoints() {
        this.startPoint.set({ visible: false });
        this.endPoint.set({ visible: false });
        this.canvas.renderAll();
    }

    _createPath(options) {
        // Create path for the elbow connector using SVG string
        this.path = new fabric.Path(this._createPathString(options.startX, options.startY, options.elbowX, options.elbowY, options.endX, options.endY), {
            fill: '',
            stroke: 'black',
            strokeWidth: 2,
            hasBorders: false,

            class:'line',
            strokeDashArray: [], // Default to solid
            selectable: true,
            evented: true,
            hasControls: false
        });
    }

    _updatePath() {
        const startX = this.startPoint.left + this.startPoint.radius;
        const startY = this.startPoint.top + this.startPoint.radius;
        const endX = this.endPoint.left + this.endPoint.radius;
        const endY = this.endPoint.top + this.endPoint.radius;

        const elbowX = (startX + endX) / 2;

        // Create and set a new path
        this.canvas.remove(this.path); // Remove old path
        this._createPath({
            startX,
            startY,
            elbowX,
            elbowY: endY,
            endX,
            endY
        });
        this.canvas.add(this.path); // Add new path

        this._bindSelectionEvents();
        this._bindEvents();
        // Render the canvas to update the path
        this.canvas.renderAll();
    }

    _updateControlPoints() {
        try {
            // Get path's current coordinates
            const pathCoords = this.path.path;
            
            // Extract the start and end coordinates from the path data
            const startPoint = pathCoords[0]; // 'M' command
            const endPoint = pathCoords[pathCoords.length - 1]; // Last 'L' command

            const startX = this.path.left;
            const startY = this.path.top;
            const endX = this.path.left+ this.path.width;
            const endY = this.path.top+ this.path.height;

            // Update control points' positions
            this.startPoint.set({
                left: startX,
                top: startY
            });

            this.endPoint.set({
                left: endX,
                top: endY
            });
            console.error('updating control points: new positions', this.startPoint, this.endPoint);

            this.canvas.renderAll();
        } catch (error) {
            console.error('Error updating control points:', error);
        }
    }

    _createPathString(startX, startY, elbowX, elbowY, endX, endY) {
        return `M ${startX} ${startY} L ${elbowX} ${startY} L ${elbowX} ${endY} L ${endX} ${endY}`;
    }

    // Method to set the stroke style
    setStrokeStyle(style) {
        const dashArray = this.getDashArray(style);
        this.path.set({ strokeDashArray: dashArray });
        this.canvas.renderAll();
    }

    // Method to get dash array based on style
    getDashArray(style) {
        switch (style) {
            case 'dotted':
                return [1, 2];
            case 'dashed':
                return [10, 5];
            case 'dashed-dotted':
                return [10, 5, 1, 5];
            case 'solid':
            default:
                return [];
        }
    }
}

  export class StraightConnectorLine {
    constructor(canvas, options) {
      this.canvas = canvas;
      this.line = new fabric.Line([options.startX, options.startY, options.endX, options.endY], {
        stroke: 'black',
        strokeWidth: 2,
        selectable: true,
        class:'line',
        hasBorders: false,
        evented: true,
        hasControls: false
      });
  
      // Create start and end control points
      this.startPoint = new fabric.Circle({
        left: options.startX,
        top: options.startY,
        radius: this.line.strokeWidth * 2.5,
        fill: 'blue',
        hasControls: false,
        hasBorders: false,
        lockRotation: true,
        lockScalingX: true,
        lockScalingY: true,
        originX: 'center',
        originY: 'center',
        visible:false
      });
  
      this.endPoint = new fabric.Circle({
        left: options.endX,
        top: options.endY,
        radius: this.line.strokeWidth * 2.5,
        fill: 'blue',
        hasControls: false,
        hasBorders: false,
        lockRotation: true,
        lockScalingX: true,
        lockScalingY: true,
        originX: 'center',
        originY: 'center',
        visible:false

      });
  
      // Add objects to the canvas
      try {
        this.canvas.add(this.line, this.startPoint, this.endPoint);
      } catch (error) {
        console.error('Error adding objects to canvas:', error);
      }
  
      // Bind moving events
      this._bindEvents();
      this._bindSelectionEvents();

    }
    _bindSelectionEvents() {
        this.line.on('selected', () => this._showControlPoints());
        this.line.on('deselected', () => this._hideControlPoints());
    }

    _showControlPoints() {
        this.startPoint.set({ visible: true });
        this.endPoint.set({ visible: true });
        this.canvas.renderAll();
    }

    _hideControlPoints() {
        this.startPoint.set({ visible: false });
        this.endPoint.set({ visible: false });
        this.canvas.renderAll();
    }
    _bindEvents() {
      // Ensure event listeners are properly bound
      this.startPoint.on('moving', () => this._updateLine());
      this.endPoint.on('moving', () => this._updateLine());
      this.line.on('moving', () => this._updateControlPoints());
    }
  
    _updateLine() {
        // Ensure coordinates are correctly updated
        const startX = this.startPoint.left;
        const startY = this.startPoint.top;
        const endX = this.endPoint.left;
        const endY = this.endPoint.top;
    
        try {
          this.line.set({ x1: startX, y1: startY, x2: endX, y2: endY });
          this.canvas.renderAll();
        } catch (error) {
          console.error('Error updating line:', error);
        }
      }
      
      _updateControlPoints() {
        try {
          const centerX = this.line.left;
          const centerY = this.line.top ;
      
          this.startPoint.set({
            left: centerX,
            top: centerY 
          });
      
          this.endPoint.set({
            left: centerX + this.line.x2 - this.line.x1,
            top: centerY + this.line.y2 - this.line.y1
          });
      
          this.canvas.renderAll();
        } catch (error) {
          console.error('Error updating control points:', error);
        }
      }
      
  }
 
export class ArrowConnectorLine {
    constructor(canvas, options) {
        this.canvas = canvas;

        // Create start and end control points
        this.startPoint = new fabric.Circle({
            left: options.startX,
            top: options.startY,
            radius: 5,
            fill: 'blue',
            hasControls: false,
            hasBorders: false,
            lockRotation: true,
            lockScalingX: true,
            lockScalingY: true,
            originX: 'center',
            visible:false,
            originY: 'center'
        });

        this.endPoint = new fabric.Circle({
            left: options.endX,
            top: options.endY,
            visible:false,
            radius: 5,
            fill: 'blue',
            hasControls: false,
            hasBorders: false,
            lockRotation: true,
            lockScalingX: true,
            lockScalingY: true,
            originX: 'center',
            originY: 'center'
        });

        // Create the line for the connector
        this.line = new fabric.Line([options.startX, options.startY, options.endX, options.endY], {
            stroke: 'black',
            strokeWidth: 2,
        hasBorders: false,

            class:'line',
            hasControls:false,
            selectable: true,
            evented: true
        });

        // Create the arrowhead
        this.arrowhead = this._createArrowhead(options.startX, options.startY, options.endX, options.endY);

        // Add objects to the canvas
        this.canvas.add(this.line, this.startPoint, this.endPoint, this.arrowhead);

        // Bind moving events
        this._bindEvents();
        this._bindSelectionEvents();
    }

    _bindEvents() {
        // Ensure event listeners are properly bound
        this.startPoint.on('moving', () => this._updateLine());
        this.endPoint.on('moving', () => this._updateLine());
        this.line.on('moving', () => this._updateControlPoints());

    }
    _bindSelectionEvents() {
        this.line.on('selected', () => this._showControlPoints());
        this.line.on('deselected', () => this._hideControlPoints());
    }

    _showControlPoints() {
        this.startPoint.set({ visible: true });
        this.endPoint.set({ visible: true });
        this.canvas.renderAll();
    }

    _hideControlPoints() {
        this.startPoint.set({ visible: false });
        this.endPoint.set({ visible: false });
        this.canvas.renderAll();
    }
    _updateControlPoints() {
        try {
          const centerX = this.line.left;
          const centerY = this.line.top ;
      
          this.startPoint.set({
            left: centerX,
            top: centerY 
          });
      
          this.endPoint.set({
            left: centerX + this.line.x2 - this.line.x1,
            top: centerY + this.line.y2 - this.line.y1
          });
          this.arrowhead.set({
            left: centerX + this.line.x2 - this.line.x1- this.endArrowhead.radius,
            top: centerY + this.line.y2 - this.line.y1- this.endArrowhead.radius
          });
      
          this.canvas.renderAll();
        } catch (error) {
          console.error('Error updating control points:', error);
        }
      }
    _createArrowhead(startX, startY, endX, endY) {
        // Calculate the angle of the line
        const angle = Math.atan2(endY - startY, endX - startX);

        // Calculate the arrowhead points
        const arrowSize = 10; // Size of the arrowhead
        const arrowheadPoints = [
            { x: endX, y: endY },
            { x: endX - arrowSize * Math.cos(angle - Math.PI / 6), y: endY - arrowSize * Math.sin(angle - Math.PI / 6) },
            { x: endX - arrowSize * Math.cos(angle + Math.PI / 6), y: endY - arrowSize * Math.sin(angle + Math.PI / 6) }
        ];

        // Create the arrowhead path
        const arrowheadPath = new fabric.Polyline(arrowheadPoints, {
            fill: 'black',
            stroke: 'black',
            strokeWidth: 2,
            selectable: true,
            evented: false,
            closed: true
        });

        return arrowheadPath;
    }

    _updateLine() {
        // Ensure coordinates are correctly updated
        const startX = this.startPoint.left;
        const startY = this.startPoint.top;
        const endX = this.endPoint.left;
        const endY = this.endPoint.top;

        this.line.set({ x1: startX, y1: startY, x2: endX, y2: endY });

        // Update the arrowhead position
        const newArrowhead = this._createArrowhead(startX, startY, endX, endY);
        this.canvas.remove(this.arrowhead);
        this.arrowhead = newArrowhead;
        this.canvas.add(this.arrowhead);

        // Render the canvas to update the line and arrowhead
        this.canvas.renderAll();
    }
}

export class DoubleArrowConnectorLine {
    constructor(canvas, options) {
        this.canvas = canvas;

        // Create start and end control points
        this.startPoint = new fabric.Circle({
            left: options.startX,
            top: options.startY,
            radius: 5,
            fill: 'blue',
            hasControls: false,
            hasBorders: false,
            lockRotation: true,
            lockScalingX: true,
            lockScalingY: true,
            visible:false,
            originX: 'center',
            originY: 'center'
        });

        this.endPoint = new fabric.Circle({
            left: options.endX,
            top: options.endY,
            radius: 5,
            fill: 'blue',
            hasControls: false,
            visible:false,
            hasBorders: false,
            lockRotation: true,
            lockScalingX: true,
            lockScalingY: true,
            originX: 'center',
            originY: 'center'
        });

        // Create the line for the connector
        this.line = new fabric.Line([options.startX, options.startY, options.endX, options.endY], {
            stroke: 'black',
            strokeWidth: 2,
            class:'line',
            hasBorders:false,
            selectable: true,
            evented: true,
            hasControls: false
        });

        // Create the arrowheads
        this.startArrowhead = this._createArrowhead(options.startX, options.startY, options.endX, options.endY, true);
        this.endArrowhead = this._createArrowhead(options.startX, options.startY, options.endX, options.endY, false);

        // Add objects to the canvas
        this.canvas.add(this.line, this.startPoint, this.endPoint, this.startArrowhead, this.endArrowhead);

        // Bind moving events
        this._bindEvents();
        this._bindSelectionEvents();
    }

    _bindEvents() {
        // Ensure event listeners are properly bound
        this.line.on('moving', () => this._updateControlPoints());

        this.startPoint.on('moving', () => this._updateLine());
        this.endPoint.on('moving', () => this._updateLine());
    }
    _bindSelectionEvents() {
        this.line.on('selected', () => this._showControlPoints());
        this.line.on('deselected', () => this._hideControlPoints());
    }

    _showControlPoints() {
        this.startPoint.set({ visible: true });
        this.endPoint.set({ visible: true });
        this.canvas.renderAll();
    }

    _hideControlPoints() {
        this.startPoint.set({ visible: false });
        this.endPoint.set({ visible: false });
        this.canvas.renderAll();
    }
    _updateControlPoints() {
        try {
          const centerX = this.line.left;
          const centerY = this.line.top ;
      
          this.startPoint.set({
            left: centerX,
            top: centerY 
          });
          this.startArrowhead.set({
            left: centerX,
            top: centerY 
          });
          this.endPoint.set({
            left: centerX + this.line.x2 - this.line.x1,
            top: centerY + this.line.y2 - this.line.y1
          });
          this.endArrowhead.set({
            left: centerX + this.line.x2 - this.line.x1 - this.endArrowhead.radius,
            top: centerY + this.line.y2 - this.line.y1 - this.endArrowhead.radius
          });
      
          this.canvas.renderAll();
        } catch (error) {
          console.error('Error updating control points:', error);
        }
      }
    _createArrowhead(startX, startY, endX, endY, isStart) {
        // Calculate the angle of the line
        const angle = Math.atan2(endY - startY, endX - startX);

        // Size of the arrowhead
        const arrowSize = 10;
        
        // Calculate the arrowhead points
        const arrowheadPoints = [
            { x: isStart ? startX : endX, y: isStart ? startY : endY },
            { x: (isStart ? startX : endX) + arrowSize * Math.cos(angle + (isStart ? Math.PI / 6 : -Math.PI / 6)),
              y: (isStart ? startY : endY) + arrowSize * Math.sin(angle + (isStart ? Math.PI / 6 : -Math.PI / 6)) },
            { x: (isStart ? startX : endX) + arrowSize * Math.cos(angle - (isStart ? Math.PI / 6 : -Math.PI / 6)),
              y: (isStart ? startY : endY) + arrowSize * Math.sin(angle - (isStart ? Math.PI / 6 : -Math.PI / 6)) }
        ];

        // Create the arrowhead path
        return new fabric.Polygon(arrowheadPoints, {
            fill: 'black',
            stroke: 'black',
            strokeWidth: 2,
            selectable: false,
            evented: false,
            closed: true
        });
    }

    _updateLine() {
        // Ensure coordinates are correctly updated
        const startX = this.startPoint.left + this.startPoint.radius;
        const startY = this.startPoint.top + this.startPoint.radius;
        const endX = this.endPoint.left + this.endPoint.radius;
        const endY = this.endPoint.top + this.endPoint.radius;

        this.line.set({ x1: startX, y1: startY, x2: endX, y2: endY });

        // Update the arrowheads
        const newStartArrowhead = this._createArrowhead(startX, startY, endX, endY, true);
        const newEndArrowhead = this._createArrowhead(startX, startY, endX, endY, false);

        this.canvas.remove(this.startArrowhead, this.endArrowhead);
        this.startArrowhead = newStartArrowhead;
        this.endArrowhead = newEndArrowhead;
        this.canvas.add(this.startArrowhead, this.endArrowhead);

        // Render the canvas to update the line and arrowheads
        this.canvas.renderAll();
    }
}


export class CurvedArrowConnector {
    constructor(canvas, options) {
        this.canvas = canvas;

        // Create start and end control points
        this.startPoint = new fabric.Circle({
            left: options.startX,
            top: options.startY,
            radius: 5,
            fill: 'blue',
            hasControls: false,
            hasBorders: false,
            lockRotation: true,
            lockScalingX: true,
            lockScalingY: true,
            originX: 'center',
            originY: 'center'
        });

        this.endPoint = new fabric.Circle({
            left: options.endX,
            top: options.endY,
            radius: 5,
            fill: 'blue',
            hasControls: false,
            hasBorders: false,
            lockRotation: true,
            lockScalingX: true,
            lockScalingY: true,
            originX: 'center',
            originY: 'center'
        });

        // Create the initial path
        this.path = new fabric.Path(this._createPathString(options), {
            stroke: 'black',
            strokeWidth: 2,
            fill: 'transparent',
            hasBorders:false,
            class: 'line',
            hasControls:false,

            selectable: true,
            evented: true
        });

        // Create the arrowhead
        this.arrowhead = this._createArrowhead(options.startX, options.startY, options.endX, options.endY);

        // Add objects to the canvas
        this.canvas.add(this.path, this.startPoint, this.endPoint, this.arrowhead);

        // Bind moving events
        this._bindEvents();
        this._bindSelectionEvents();
    }

    _bindEvents() {
        this.startPoint.on('moving', () => this._updatePath());
        this.endPoint.on('moving', () => this._updatePath());
        this.path.on('moving', () => this._updateControlPoints());

    }
    _bindSelectionEvents() {
        this.path.on('selected', () => this._showControlPoints());
        this.path.on('deselected', () => this._hideControlPoints());
    }

    _showControlPoints() {
        this.startPoint.set({ visible: true });
        this.endPoint.set({ visible: true });
        this.canvas.renderAll();
    }

    _hideControlPoints() {
        this.startPoint.set({ visible: false });
        this.endPoint.set({ visible: false });
        this.canvas.renderAll();
    }
    _updateControlPoints() {
        try {
            // Get path's current coordinates
            const pathCoords = this.path.path;
            
            // Extract the start and end coordinates from the path data
            const startPoint = pathCoords[0]; // 'M' command
            const endPoint = pathCoords[pathCoords.length - 1]; // Last 'L' command

            const startX = this.path.left;
            const startY = this.path.top;
            const endX = this.path.left+ this.path.width;
            const endY = this.path.top+ this.path.height;

            // Update control points' positions
            this.startPoint.set({
                left: startX,
                top: startY
            });

            this.endPoint.set({
                left: endX,
                top: endY
            });
            this.arrowhead.set({
                left: endX,
                top: endY
            });
            console.error('updating control points: new positions', this.startPoint, this.endPoint);

            this.canvas.renderAll();
        } catch (error) {
            console.error('Error updating control points:', error);
        }
    }

    _createPathString(options) {
        // Create a Bezier curve
        const controlPoint1X = (options.startX + options.endX) / 2;
        const controlPoint1Y = options.startY;
        const controlPoint2X = (options.startX + options.endX) / 2;
        const controlPoint2Y = options.endY;

        return `M ${options.startX} ${options.startY} C ${controlPoint1X} ${controlPoint1Y}, ${controlPoint2X} ${controlPoint2Y}, ${options.endX} ${options.endY}`;
    }

    _createArrowhead(startX, startY, endX, endY) {
        // Calculate the angle of the path
        const angle = Math.atan2(endY - startY, endX - startX);

        // Calculate the arrowhead points
        const arrowSize = 10; // Size of the arrowhead
        const arrowheadPoints = [
            { x: endX, y: endY },
            { x: endX - arrowSize * Math.cos(angle - Math.PI / 6), y: endY - arrowSize * Math.sin(angle - Math.PI / 6) },
            { x: endX - arrowSize * Math.cos(angle + Math.PI / 6), y: endY - arrowSize * Math.sin(angle + Math.PI / 6) }
        ];

        // Create the arrowhead path
        return new fabric.Polygon(arrowheadPoints, {
            fill: 'black',
            stroke: 'black',
            strokeWidth: 2,
            selectable: false,
            evented: false,
            closed: true

        });
    }

    _updatePath() {
        // Ensure coordinates are correctly updated
        const startX = this.startPoint.left + this.startPoint.radius;
        const startY = this.startPoint.top + this.startPoint.radius;
        const endX = this.endPoint.left + this.endPoint.radius;
        const endY = this.endPoint.top + this.endPoint.radius;

        // Create a new path
        const newPath = new fabric.Path(this._createPathString({ startX, startY, endX, endY }), {
            stroke: this.path.stroke,
            strokeWidth: this.path.strokeWidth,
            fill: 'transparent',
            class: 'line',
            selectable: true,
            hasBorders:false,
            hasControls:false,
            evented: true
        });

        // Remove the old path
        this.canvas.remove(this.path);

        // Add the new path
        this.path = newPath;
        this.canvas.add(this.path);

        // Update the arrowhead
        this.canvas.remove(this.arrowhead);
        this.arrowhead = this._createArrowhead(startX, startY, endX, endY);
        this.canvas.add(this.arrowhead);
        this._bindSelectionEvents();
        this._bindEvents();
        // Render the canvas to update the path and arrowhead
        this.canvas.renderAll();
    }

    // Method to set the stroke style
    setStrokeStyle(style) {
        const dashArray = this.getDashArray(style);
        this.path.set({ strokeDashArray: dashArray });
        this.canvas.renderAll();
    }

    // Method to get dash array based on style
    getDashArray(style) {
        switch (style) {
            case 'dotted':
                return [1, 2];
            case 'dashed':
                return [10, 5];
            case 'dashed-dotted':
                return [10, 5, 1, 5];
            case 'solid':
            default:
                return [];
        }
    }
}

export class CurvedDoubleArrowConnector {
    constructor(canvas, options) {
        this.canvas = canvas;

        // Create start and end control points
        this.startPoint = new fabric.Circle({
            left: options.startX,
            top: options.startY,
            radius: 5,
            fill: 'blue',
            hasControls: false,
            visible:false,
            hasBorders: false,
            lockRotation: true,
            lockScalingX: true,
            lockScalingY: true,
            originX: 'center',
            originY: 'center'
        });

        this.endPoint = new fabric.Circle({
            left: options.endX,
            top: options.endY,
            visible:false,
            radius: 5,
            fill: 'blue',
            hasControls: false,
            hasBorders: false,
            lockRotation: true,
            lockScalingX: true,
            lockScalingY: true,
            originX: 'center',
            originY: 'center'
        });

        // Create the initial path
        this.path = new fabric.Path(this._createPathString(options), {
            stroke: 'black',
            strokeWidth: 2,
            fill: 'transparent',
            class: 'line',
            hasBorders:false,
            hasControls:false,

            selectable: true,
            evented: true
        });

        // Create the arrowheads
        this.startArrowhead = this._createArrowhead(options.startX, options.startY, options.endX, options.endY, true);
        this.endArrowhead = this._createArrowhead(options.startX, options.startY, options.endX, options.endY, false);

        // Add objects to the canvas
        this.canvas.add(this.path, this.startPoint, this.endPoint, this.startArrowhead, this.endArrowhead);

        // Bind moving events
        this._bindEvents();
        this._bindSelectionEvents();
    }
    _bindSelectionEvents() {
        this.path.on('selected', () => this._showControlPoints());
        this.path.on('deselected', () => this._hideControlPoints());
    }

    _showControlPoints() {
        this.startPoint.set({ visible: true });
        this.endPoint.set({ visible: true });
        this.canvas.renderAll();
    }

    _hideControlPoints() {
        this.startPoint.set({ visible: false });
        this.endPoint.set({ visible: false });
        this.canvas.renderAll();
    }
    _bindEvents() {
        this.startPoint.on('moving', () => this._updatePath());
        this.endPoint.on('moving', () => this._updatePath());
        this.path.on('moving', () => this._updateControlPoints());

    }
    _updateControlPoints() {
        try {
            // Get path's current coordinates
            const pathCoords = this.path.path;
            
            // Extract the start and end coordinates from the path data
            const startPoint = pathCoords[0]; // 'M' command
            const endPoint = pathCoords[pathCoords.length - 1]; // Last 'L' command

            const startX = this.path.left;
            const startY = this.path.top;
            const endX = this.path.left+ this.path.width;
            const endY = this.path.top+ this.path.height;

            // Update control points' positions
            this.startPoint.set({
                left: startX,
                top: startY
            });
            this.startArrowhead.set({
                left: startX,
                top: startY
            });

            this.endPoint.set({
                left: endX,
                top: endY
            });
            this.endArrowhead.set({
                left: endX,
                top: endY
            });
            console.error('updating control points: new positions', this.startPoint, this.endPoint);

            this.canvas.renderAll();
        } catch (error) {
            console.error('Error updating control points:', error);
        }
    }

    _createPathString(options) {
        // Create a Bezier curve
        const controlPoint1X = (options.startX + options.endX) / 2;
        const controlPoint1Y = options.startY;
        const controlPoint2X = (options.startX + options.endX) / 2;
        const controlPoint2Y = options.endY;

        return `M ${options.startX} ${options.startY} C ${controlPoint1X} ${controlPoint1Y}, ${controlPoint2X} ${controlPoint2Y}, ${options.endX} ${options.endY}`;
    }

    _createArrowhead(startX, startY, endX, endY, isStart) {
        // Calculate the angle of the path
        const angle = Math.atan2(endY - startY, endX - startX);
        const arrowSize = 10; // Size of the arrowhead

        // Calculate the position of the arrowhead
        const arrowheadEndX = isStart ? startX : endX;
        const arrowheadEndY = isStart ? startY : endY;
        const arrowheadPoints = [
            { x: arrowheadEndX, y: arrowheadEndY },
            { x: arrowheadEndX - arrowSize * Math.cos(angle - Math.PI / 6), y: arrowheadEndY - arrowSize * Math.sin(angle - Math.PI / 6) },
            { x: arrowheadEndX - arrowSize * Math.cos(angle + Math.PI / 6), y: arrowheadEndY - arrowSize * Math.sin(angle + Math.PI / 6) }
        ];

        // Create the arrowhead path
        return new fabric.Polygon(arrowheadPoints, {
            fill: 'black',
            stroke: 'black',
            strokeWidth: 2,
            selectable: false,
            evented: false,
            closed: true
        });
    }

    _updatePath() {
        const startX = this.startPoint.left + this.startPoint.radius;
        const startY = this.startPoint.top + this.startPoint.radius;
        const endX = this.endPoint.left + this.endPoint.radius;
        const endY = this.endPoint.top + this.endPoint.radius;
    
        // Remove the old path from the canvas
        this.canvas.remove(this.path);
    
        // Create a new path
        this.path = new fabric.Path(this._createPathString({ startX, startY, endX, endY }), {
            stroke: 'black',
            strokeWidth: 2,
            hasControls:false,
            hasBorders:false,
            fill: 'transparent',
            class: 'line',
            selectable: true,
            evented: true
        });
    
        // Add the new path to the canvas
        this.canvas.add(this.path);
    
        // Update the arrowheads
        const newStartArrowhead = this._createArrowhead(startX, startY, endX, endY, true);
        const newEndArrowhead = this._createArrowhead(startX, startY, endX, endY, false);
        
        this.canvas.remove(this.startArrowhead);
        this.canvas.remove(this.endArrowhead);
        
        this.startArrowhead = newStartArrowhead;
        this.endArrowhead = newEndArrowhead;
        
        this.canvas.add(this.startArrowhead);
        this.canvas.add(this.endArrowhead);
        this._bindSelectionEvents();
        this._bindEvents();
        // Render the canvas to update the path and arrowheads
        this.canvas.renderAll();
    }
    

    // Method to set the stroke style
    setStrokeStyle(style) {
        const dashArray = this.getDashArray(style);
        this.path.set({ strokeDashArray: dashArray });
        this.canvas.renderAll();
    }

    // Method to get dash array based on style
    getDashArray(style) {
        switch (style) {
            case 'dotted':
                return [1, 2];
            case 'dashed':
                return [10, 5];
            case 'dashed-dotted':
                return [10, 5, 1, 5];
            case 'solid':
            default:
                return [];
        }
    }
}



export class CurvedPathConnector {
    constructor(canvas, options) {
        this.canvas = canvas;

        // Create start and end control points
        this.startPoint = new fabric.Circle({
            left: options.startX,
            top: options.startY,
            radius: 5,
            fill: 'blue',
            hasControls: false,
            hasBorders: false,
            lockRotation: true,
            lockScalingX: true,
            lockScalingY: true,
            visible:false,
            originX: 'center',
            originY: 'center'
        });

        this.endPoint = new fabric.Circle({
            left: options.endX,
            top: options.endY,
            radius: 5,
            visible:false,
            fill: 'blue',
            hasControls: false,
            hasBorders: false,
            lockRotation: true,
            lockScalingX: true,
            lockScalingY: true,
            originX: 'center',
            originY: 'center'
        });

        // Create the initial path
        this.path = new fabric.Path(this._createPathString(options), {
            stroke: 'black',
            strokeWidth: 2,
            fill: 'transparent',
            class: 'line',
            hasBorders:false,
            selectable: true,
            hasControls:false,
            evented: true
        });

        // Add objects to the canvas
        this.canvas.add(this.path, this.startPoint, this.endPoint);

        // Bind moving events
        this._bindEvents();
        this._bindSelectionEvents();
    }

    _bindEvents() {
        this.startPoint.on('moving', () => this._updatePath());
        this.endPoint.on('moving', () => this._updatePath());
        this.path.on('moving', () => this._updateControlPoints());

    }

    _bindSelectionEvents() {
        this.path.on('selected', () => this._showControlPoints());
        this.path.on('deselected', () => this._hideControlPoints());
    }

    _showControlPoints() {
        this.startPoint.set({ visible: true });
        this.endPoint.set({ visible: true });
        this.canvas.renderAll();
    }

    _hideControlPoints() {
        this.startPoint.set({ visible: false });
        this.endPoint.set({ visible: false });
        this.canvas.renderAll();
    }
    _updateControlPoints() {
        try {
            // Get path's current coordinates
            const pathCoords = this.path.path;
            
            // Extract the start and end coordinates from the path data
            const startPoint = pathCoords[0]; // 'M' command
            const endPoint = pathCoords[pathCoords.length - 1]; // Last 'L' command

            const startX = this.path.left;
            const startY = this.path.top;
            const endX = this.path.left+ this.path.width;
            const endY = this.path.top+ this.path.height;

            // Update control points' positions
            this.startPoint.set({
                left: startX,
                top: startY
            });

            this.endPoint.set({
                left: endX,
                top: endY
            });
            console.error('updating control points: new positions', this.startPoint, this.endPoint);

            this.canvas.renderAll();
        } catch (error) {
            console.error('Error updating control points:', error);
        }
    }
    _createPathString(options) {
        // Create a Bezier curve
        const controlPoint1X = (options.startX + options.endX) / 2;
        const controlPoint1Y = options.startY;
        const controlPoint2X = (options.startX + options.endX) / 2;
        const controlPoint2Y = options.endY;

        return `M ${options.startX} ${options.startY} C ${controlPoint1X} ${controlPoint1Y}, ${controlPoint2X} ${controlPoint2Y}, ${options.endX} ${options.endY}`;
    }

    _updatePath() {
        // Ensure coordinates are correctly updated
        const startX = this.startPoint.left + this.startPoint.radius;
        const startY = this.startPoint.top + this.startPoint.radius;
        const endX = this.endPoint.left + this.endPoint.radius;
        const endY = this.endPoint.top + this.endPoint.radius;

        // Create a new path
        const newPath = new fabric.Path(this._createPathString({ startX, startY, endX, endY }), {
            stroke: this.path.stroke,
            strokeWidth: this.path.strokeWidth,
            fill: 'transparent',
            class: 'line',
            selectable: true,
            hasBorders:false,
            hasControls:false,

            evented: true
        });

        // Remove the old path
        this.canvas.remove(this.path);

        // Add the new path
        this.path = newPath;
        this.canvas.add(this.path);
        this._bindSelectionEvents();
        this._bindEvents();
        // Render the canvas to update the path
        this.canvas.renderAll();
    }

    // Method to set the stroke style
    setStrokeStyle(style) {
        const dashArray = this.getDashArray(style);
        this.path.set({ strokeDashArray: dashArray });
        this.canvas.renderAll();
    }

    // Method to get dash array based on style
    getDashArray(style) {
        switch (style) {
            case 'dotted':
                return [1, 2];
            case 'dashed':
                return [10, 5];
            case 'dashed-dotted':
                return [10, 5, 1, 5];
            case 'solid':
            default:
                return [];
        }
    }
}



export class CirclePath {
    constructor(canvas, options) {
        this.canvas = canvas;
        this.startAngle = options.startAngle || 0;
        this.endAngle = options.endAngle || 360;
        this.radius = options.radius || 50;
        this.left = options.left || 0;
        this.top = options.top || 0;
        this.angleType = options.angleType || 'arc'; // Default to 'arc'
        this.originX = options.originX;
        this.originY = options.originY;
        this._createCirclePath();

        // Attach instance to path for later use
        this.path.circlePathInstance = this;
    }

    _createCirclePath() {
        // Capture current transformations
        const transforms = this.path ? {
            scaleX: this.path.scaleX,
            scaleY: this.path.scaleY,
            angle: this.path.angle,
            skewX: this.path.skewX,
            skewY: this.path.skewY,
            left: this.path.left,
            top: this.path.top,
        } : {};

        if (this.path) {
            this.canvas.remove(this.path);
        }

        this.path = new fabric.Path(this._createPathArray(), {
            stroke: 'black',
            strokeWidth: 2,
            fill: 'transparent',
            selectable: true,
            evented: true,
            radius:this.radius,
            left: this.left,
            top: this.top,
            class: 'circle',
            ...transforms, // Reapply the captured transformations
        });

        this.path.setStartAngle = (angle) => {
            this.setStartAngle(angle);
        };
        
        this.path.setEndAngle = (angle) => {
            this.setEndAngle(angle);
        };

        this.path.setRadius = (radius) => {
            this.setRadius(radius);
        };

        this.path.setAngleType = (type) => {
            this.setAngleType(type);
        };

        this.canvas.add(this.path);
        this.canvas.renderAll();
    }

    _createPathArray() {
        const centerX = this.left + this.radius;
        const centerY = this.top + this.radius;
        const startPoint = this._calculatePoint(this.startAngle);
        const endPoint = this._calculatePoint(this.endAngle);
        const largeArcFlag = (this.endAngle - this.startAngle) >= 180 ? "1" : "0";
        const sweepFlag = (this.endAngle - this.startAngle) >= 0 ? "1" : "0";

        let pathArray;
        if (this.angleType === 'chord') {
            // Chord segment
            if(Math.abs(this.endAngle - this.startAngle) === 360){
                pathArray = [
                    ['M', startPoint.x, startPoint.y],
                    ['A', this.radius, this.radius, 0, largeArcFlag, sweepFlag, endPoint.x, endPoint.y],
                    ['Z']
                ];
            } else {
                pathArray = [
                    ['M', startPoint.x, startPoint.y],
                    ['A', this.radius, this.radius, 0, largeArcFlag, sweepFlag, endPoint.x, endPoint.y],
                    ['L', endPoint.x, endPoint.y],
                    ['Z']
                ];
            }
        } else if (this.startAngle === this.endAngle || Math.abs(this.endAngle - this.startAngle) === 360) {
            // Full circle
            pathArray = [
                ['M', centerX, centerY - this.radius],
                ['A', this.radius, this.radius, 0, 1, 1, centerX, centerY + this.radius],
                ['A', this.radius, this.radius, 0, 1, 1, centerX, centerY - this.radius],
            ];
        } else {
            // Arc segment
            pathArray = [
                ['M', startPoint.x, startPoint.y],
                ['A', this.radius, this.radius, 0, largeArcFlag, sweepFlag, endPoint.x, endPoint.y],
                ['L', centerX, centerY],
                ['Z']
            ];
        }

        return pathArray;
    }

    _calculatePoint(angle) {
        const rad = fabric.util.degreesToRadians(angle);
        return {
            x: this.left + this.radius + this.radius * Math.cos(rad),
            y: this.top + this.radius + this.radius * Math.sin(rad)
        };
    }

    _updatePath() {
        this._createCirclePath();
    }

    setRadius(radius) {
        this.radius = radius;
        this._updatePath();
    }

    setStartAngle(angle) {
        this.startAngle = angle;
        this._updatePath();
    }

    setEndAngle(angle) {
        this.endAngle = angle;
        this._updatePath();
    }

    setAngleType(type) {
        this.angleType = type;
        this._updatePath();
    }
}

export class ElbowConnectorLineWithArrow {
    constructor(canvas, options) {
        this.canvas = canvas;

        // Create start and end control points
        this.startPoint = new fabric.Circle({
            left: options.startX,
            top: options.startY,
            radius: 5,
            fill: 'blue',
            hasControls: false,
            hasBorders: false,
            lockRotation: true,
            lockScalingX: true,
            lockScalingY: true,
            originX: 'center',
            originY: 'center'
        });

        this.endPoint = new fabric.Circle({
            left: options.endX,
            top: options.endY,
            radius: 5,
            fill: 'blue',
            hasControls: false,
            hasBorders: false,
            lockRotation: true,
            lockScalingX: true,
            lockScalingY: true,
            originX: 'center',
            originY: 'center'
        });

        // Create and add the initial path and arrowhead
        this._createPath(options);
        this._createArrowhead(options);

        // Add objects to the canvas
        this.canvas.add(this.path, this.startPoint, this.endPoint, this.arrowHead);

        // Bind moving events
        this._bindEvents();
        this._bindSelectionEvents();
    }
    _bindSelectionEvents() {
        this.path.on('selected', () => this._showControlPoints());
        this.path.on('deselected', () => this._hideControlPoints());
    }

    _showControlPoints() {
        this.startPoint.set({ visible: true });
        this.endPoint.set({ visible: true });
        this.canvas.renderAll();
    }

    _hideControlPoints() {
        this.startPoint.set({ visible: false });
        this.endPoint.set({ visible: false });
        this.canvas.renderAll();
    }
    _bindEvents() {
        this.startPoint.on('moving', () => this._updatePathAndArrowhead());
        this.endPoint.on('moving', () => this._updatePathAndArrowhead());
        this.path.on('moving', () => this._updateControlPoints());

    }
    _updateControlPoints() {
        try {
            // Get path's current coordinates
            const pathCoords = this.path.path;
            
            // Extract the start and end coordinates from the path data
            const startPoint = pathCoords[0]; // 'M' command
            const endPoint = pathCoords[pathCoords.length - 1]; // Last 'L' command

            const startX = this.path.left;
            const startY = this.path.top;
            const endX = this.path.left+ this.path.width;
            const endY = this.path.top+ this.path.height;

            // Update control points' positions
            this.startPoint.set({
                left: startX,
                top: startY
            });
            
            this.endPoint.set({
                left: endX,
                top: endY
            });

            this.arrowHead.set({
                left: endX,
                top: endY
            });
            console.error('updating control points: new positions', this.startPoint, this.endPoint);

            this.canvas.renderAll();
        } catch (error) {
            console.error('Error updating control points:', error);
        }
    }

    _createPath(options) {
        // Create path for the elbow connector using SVG string
        this.path = new fabric.Path(this._createPathString(options.startX, options.startY, options.elbowX, options.elbowY, options.endX, options.endY), {
            fill: '',
            hasBorders:false,
            stroke: 'black',
            strokeWidth: 2,
            class: 'line',
            strokeDashArray: [], // Default to solid
            selectable: true,
            evented: true,
            hasControls: false
        });
    }

    _createArrowhead(options) {
        // Create the initial arrowhead
        this.arrowHead = new fabric.Triangle({
            left: options.endX,
            top: options.endY,
            width: 10,
            height: 10,
            fill: 'black',
            angle: 90, // Rotate the triangle to point right
            originX: 'center',
            originY: 'center',
            selectable: false,
            evented: false
        });
    }

    _updatePathAndArrowhead() {
        const startX = this.startPoint.left + this.startPoint.radius;
        const startY = this.startPoint.top + this.startPoint.radius;
        const endX = this.endPoint.left + this.endPoint.radius;
        const endY = this.endPoint.top + this.endPoint.radius;

        const elbowX = (startX + endX) / 2;

        // Update the path
        this.canvas.remove(this.path); // Remove old path
        this._createPath({
            startX,
            startY,
            elbowX,
            elbowY: endY,
            endX,
            endY
        });
        this.canvas.add(this.path); // Add new path

        // Update the position of the arrowhead
        this.arrowHead.set({
            left: endX,
            top: endY,
            angle: endY > startY ? 90 : -90 // Adjust the angle based on the direction
        });
        this._bindSelectionEvents();
        this._bindEvents();
        // Render the canvas to update the path and arrowhead
        this.canvas.renderAll();
    }

    _createPathString(startX, startY, elbowX, elbowY, endX, endY) {
        return `M ${startX} ${startY} L ${elbowX} ${startY} L ${elbowX} ${endY} L ${endX} ${endY}`;
    }

    // Method to set the stroke style
    setStrokeStyle(style) {
        const dashArray = this.getDashArray(style);
        this.path.set({ strokeDashArray: dashArray });
        this.canvas.renderAll();
    }

    // Method to get dash array based on style
    getDashArray(style) {
        switch (style) {
            case 'dotted':
                return [1, 2];
            case 'dashed':
                return [10, 5];
            case 'dashed-dotted':
                return [10, 5, 1, 5];
            case 'solid':
            default:
                return [];
        }
    }
}


export class ElbowConnectorLineWithDoubleArrow {
    constructor(canvas, options) {
        this.canvas = canvas;

        // Create start and end control points
        this.startPoint = new fabric.Circle({
            left: options.startX,
            top: options.startY,
            radius: 5,
            fill: 'blue',
            hasControls: false,
            hasBorders: false,
            lockRotation: true,
            lockScalingX: true,
            visible:false,
            lockScalingY: true,
            originX: 'center',
            originY: 'center'
        });

        this.endPoint = new fabric.Circle({
            left: options.endX,
            top: options.endY,
            radius: 5,
            fill: 'blue',
            visible:false,
            hasControls: false,
            hasBorders: false,
            lockRotation: true,
            lockScalingX: true,
            lockScalingY: true,
            originX: 'center',
            originY: 'center'
        });

        // Create and add the initial path and arrowheads
        this._createPath(options);
        this._createArrowheads(options);

        // Add objects to the canvas
        this.canvas.add(this.path, this.startPoint, this.endPoint, this.startArrowHead, this.endArrowHead);
        this._bindSelectionEvents();
        // Bind moving events
        this._bindEvents();
    }
    _bindSelectionEvents() {
        this.path.on('selected', () => this._showControlPoints());
        this.path.on('deselected', () => this._hideControlPoints());
    }

    _showControlPoints() {
        this.startPoint.set({ visible: true });
        this.endPoint.set({ visible: true });
        this.canvas.renderAll();
    }

    _hideControlPoints() {
        this.startPoint.set({ visible: false });
        this.endPoint.set({ visible: false });
        this.canvas.renderAll();
    }
    _bindEvents() {
        this.startPoint.on('moving', () => this._updatePathAndArrowheads());
        this.endPoint.on('moving', () => this._updatePathAndArrowheads());
        this.path.on('moving', () => this._updateControlPoints());

    }
    _updateControlPoints() {
        try {
            // Get path's current coordinates
            const pathCoords = this.path.path;
            
            // Extract the start and end coordinates from the path data
            const startPoint = pathCoords[0]; // 'M' command
            const endPoint = pathCoords[pathCoords.length - 1]; // Last 'L' command

            const startX = this.path.left;
            const startY = this.path.top;
            const endX = this.path.left+ this.path.width;
            const endY = this.path.top+ this.path.height;

            // Update control points' positions
            this.startPoint.set({
                left: startX,
                top: startY
            });
            this.startArrowHead.set({
                left: startX,
                top: startY
            });

            this.endPoint.set({
                left: endX,
                top: endY
            });
            this.endArrowHead.set({
                left: endX,
                top: endY
            });
            console.error('updating control points: new positions', this.startPoint, this.endPoint);

            this.canvas.renderAll();
        } catch (error) {
            console.error('Error updating control points:', error);
        }
    }

    _createPath(options) {
        // Create path for the elbow connector using SVG string
        this.path = new fabric.Path(this._createPathString(options.startX, options.startY, options.elbowX, options.elbowY, options.endX, options.endY), {
            fill: '',
            hasBorders:false,
            stroke: 'black',
            strokeWidth: 2,
            class: 'line',
            strokeDashArray: [], // Default to solid
            selectable: true,
            evented: true,
            hasControls: false
        });
    }

    _createArrowheads(options) {
        // Create the initial arrowheads
        this.startArrowHead = new fabric.Triangle({
            left: options.startX,
            top: options.startY,
            width: 10,
            height: 10,
            fill: 'black',
            angle: 270, // Rotate the triangle to point left
            originX: 'center',
            originY: 'center',
            selectable: false,
            evented: false
        });

        this.endArrowHead = new fabric.Triangle({
            left: options.endX,
            top: options.endY,
            width: 10,
            height: 10,
            fill: 'black',
            angle: 90, // Rotate the triangle to point right
            originX: 'center',
            originY: 'center',
            selectable: false,
            evented: false
        });
    }

    _updatePathAndArrowheads() {
        const startX = this.startPoint.left + this.startPoint.radius;
        const startY = this.startPoint.top + this.startPoint.radius;
        const endX = this.endPoint.left + this.endPoint.radius;
        const endY = this.endPoint.top + this.endPoint.radius;

        const elbowX = (startX + endX) / 2;

        // Update the path
        this.canvas.remove(this.path); // Remove old path
        this._createPath({
            startX,
            startY,
            elbowX,
            elbowY: endY,
            endX,
            endY
        });
        this.canvas.add(this.path); // Add new path

        // Update the position of the arrowheads
        this.startArrowHead.set({
            left: startX,
            top: startY,
            angle: 270 // Pointing left
        });

        this.endArrowHead.set({
            left: endX,
            top: endY,
            angle: 90 // Pointing right
        });
        this._bindSelectionEvents();
        this._bindEvents();
        // Render the canvas to update the path and arrowheads
        this.canvas.renderAll();
    }

    _createPathString(startX, startY, elbowX, elbowY, endX, endY) {
        return `M ${startX} ${startY} L ${elbowX} ${startY} L ${elbowX} ${endY} L ${endX} ${endY}`;
    }

    // Method to set the stroke style
    setStrokeStyle(style) {
        const dashArray = this.getDashArray(style);
        this.path.set({ strokeDashArray: dashArray });
        this.canvas.renderAll();
    }

    // Method to get dash array based on style
    getDashArray(style) {
        switch (style) {
            case 'dotted':
                return [1, 2];
            case 'dashed':
                return [10, 5];
            case 'dashed-dotted':
                return [10, 5, 1, 5];
            case 'solid':
            default:
                return [];
        }
    }
}




export class SnippedRectangle {
    constructor(canvas, options) {
        this.canvas = canvas;

        // Create a regular rectangle
        this.rectangle = new fabric.Rect({
            left: options.left,
            top: options.top,
            width: options.width,
            height: options.height,
            fill: options.fill || 'transparent',
            stroke: options.stroke || 'black',
            strokeWidth: options.strokeWidth || 1,
            selectable: options.selectable !== undefined ? options.selectable : true,
            evented: options.evented !== undefined ? options.evented : true,
            originX: 'left',
            originY: 'top'
        });

        // Create a clipping path to snip the top-right corner
        const clipPath = new fabric.Path(`M 0 0 L ${options.width - options.cornerRadius} 0 L ${options.width} ${options.cornerRadius} L ${options.width} ${options.height} L 0 ${options.height} Z`, {
            top: options.top,
            left: options.left,
            fill: 'transparent',
            stroke: 'transparent'
        });

        this.rectangle.set({
            clipPath: clipPath,
        });

        // Add objects to the canvas
        this.canvas.add(this.rectangle);

        // Log object to verify creation and addition
        console.log('Rectangle added to canvas:', this.rectangle);
    }
}
export class RoundedPolygon {
    constructor(canvas, options) {
        this.canvas = canvas;
        this.cornerRadius = options.cornerRadius || 10;
        this.points = options.points || [];

        // Create the path with rounded corners
        this.path = new fabric.Path(this.createPathData(), {
            left: options.left,
            top: options.top,
            fill: options.fill || 'transparent',
            stroke: options.stroke || 'black',
            strokeWidth: options.strokeWidth || 1,
            selectable: options.selectable !== undefined ? options.selectable : true,
            evented: options.evented !== undefined ? options.evented : true
        });

        // Add the path to the canvas
        this.canvas.add(this.path);
        this.canvas.renderAll(); // Ensure the canvas is rendered
    }

    // Method to create the path data for the polygon with rounded corners
    createPathData() {
        const { points, cornerRadius } = this;
        if (points.length < 3) return ''; // Not enough points to create a polygon

        let pathData = '';

        // Helper function to create rounded corner path data
        const createRoundedCornerPath = (p1, p2, p3, radius) => {
            const dx1 = p1.x - p2.x;
            const dy1 = p1.y - p2.y;
            const dx2 = p3.x - p2.x;
            const dy2 = p3.y - p2.y;

            const angle = (Math.atan2(dy1, dx1) - Math.atan2(dy2, dx2)) / 2;
            const tan = Math.abs(Math.tan(angle));
            const segment = radius / tan;

            const length1 = Math.sqrt(dx1 * dx1 + dy1 * dy1);
            const length2 = Math.sqrt(dx2 * dx2 + dy2 * dy2);
            const length = Math.min(length1, length2);

            if (segment > length) {
                radius = length * tan;
            }

            const getProportionPoint = (point, segment, length, dx, dy) => {
                const factor = segment / length;
                return {
                    x: point.x - dx * factor,
                    y: point.y - dy * factor
                };
            };

            const p1Cross = getProportionPoint(p2, segment, length1, dx1, dy1);
            const p2Cross = getProportionPoint(p2, segment, length2, dx2, dy2);

            const circleCenter = {
                x: p2.x * 2 - p1Cross.x - p2Cross.x,
                y: p2.y * 2 - p1Cross.y - p2Cross.y
            };

            const dx = p2.x * 2 - p1Cross.x - p2Cross.x;
            const dy = p2.y * 2 - p1Cross.y - p2Cross.y;
            const d = Math.sqrt(dx * dx + dy * dy);

            const circlePoint = getProportionPoint(p2, d, radius, dx, dy);

            const startAngle = Math.atan2(p1Cross.y - circlePoint.y, p1Cross.x - circlePoint.x);
            const endAngle = Math.atan2(p2Cross.y - circlePoint.y, p2Cross.x - circlePoint.x);
            const sweepAngle = endAngle - startAngle;

            return `M${p1Cross.x},${p1Cross.y} A${radius},${radius} 0 0,1 ${p2Cross.x},${p2Cross.y} L${p2.x},${p2.y}`;
        };

        // Loop through the points to generate the path
        for (let i = 0; i < points.length; i++) {
            const p1 = points[i];
            const p2 = points[(i + 1) % points.length];
            const p3 = points[(i + 2) % points.length];

            if (i === 0) {
                pathData += `M${p1.x},${p1.y}`;
            }

            pathData += createRoundedCornerPath(p1, p2, p3, cornerRadius);
        }

        pathData += 'Z'; // Close the path

        return pathData;
    }

    // Method to update the corner radius
    updateCornerRadius(newRadius) {
        this.cornerRadius = newRadius;
        this.path.set({ path: this.createPathData() });
        this.canvas.renderAll();
    }
}

