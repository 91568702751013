import React, { useState } from 'react';
import {
  IconButton,
  Typography,
  Tooltip,
  Select,
  MenuItem,
  TextField,
  Button,
  Checkbox
} from '@mui/material';
import { fabric } from 'fabric';
import { ArrowBack, Clear } from '@mui/icons-material';
import { PopoverListButtons } from '../../../Utils/styledComps';
import { getPoints } from '../../Tools/paintTools';
import { Bezier } from 'bezier-js';
import CustomRotateCopiesPopoverContent from './RotationCopies';
const CustomPathPopoverContent = ({
  selectedFeature,
  path,
  handleBackButtonClick,
  handlePopoverClose,
  mainCanvas,
  values,
  setValues,
  handlePathRotateCopies,
  rotationSettings,
  updateRotationSettings,         
  applyColorToSelectedCopy,
  setSelectedColorForRotationCopy,
  selectedColorForRotationCopy,
  selectedStrokeForRotationCopy,
setSelectedStrokeForRotationCopy,
  color,
  handleSpraying,
  opacityObj,
  changeOpacityOfObj
}) => {
  const handleChange = (e) =>{
    const {name, value} = e.target;
    if(name.toLowerCase().includes('rotate')){
      setValues((prev)=>({
        ...prev,
        [name]: e.target?.checked
      }))
    }else if(name.toLowerCase().includes("mode")){
    setValues((prev)=>({
      ...prev,
      [name]:value
    }))
  }else if(name === 'tangentialOff'){
    setValues((prev)=>({
      ...prev,
      [name]:value > 100 ? 100 : value < 0 ? 0 : parseFloat(value)
    }))
  }
  else{
    setValues((prev)=>({
      ...prev,
      [name]:parseFloat(value)
    }))
  }
    console.log('handleChange: ', name, e.target, values);
  }

  const [copiedObject, setCopiedObject] = useState();
  const handleCopyShape = () => {
    const activeObject = mainCanvas.getActiveObject();
    if (activeObject) {
      activeObject.clone((clonedObject) => {
        setCopiedObject(clonedObject);
        activeObject.class = "patternPath";
        mainCanvas.set({
          patternPath:clonedObject
        })
        console.log("Object copied:",clonedObject, mainCanvas);
      });
    }
  };
  function makeClonesActive(mainCanvas) {
    // Collect all objects with the "distributeCopy" class
    const clones = [];
    mainCanvas.forEachObject((obj) => {
        if (obj.class?.includes("distributeCopy")) {
            clones.push(obj);
        }
    });

    if (clones.length === 0) {
        console.warn("No distributeCopy objects found to make active.");
        return;
    }

    // Create a fabric.ActiveSelection from the clones
    const activeSelection = new fabric.ActiveSelection(clones, {
        canvas: mainCanvas, // Associate it with the canvas
    });

    // Set it as the canvas's active object
    mainCanvas.setActiveObject(activeSelection);

    // Render changes
    mainCanvas.requestRenderAll();
}

  function distributeAlongPath() {
    const activeObj = mainCanvas.getActiveObject();

    if (!activeObj || !mainCanvas.patternPath) {
        console.error("Active object or pattern path is missing!");
        return;
    }
    mainCanvas.forEachObject((obj) => {
      if(obj.class?.includes("distributeCopy")){
        mainCanvas.remove(obj);
      }
    })

    const patternPath = mainCanvas.patternPath;


    const patternBb = patternPath.getBoundingRect();
    const patternDimension = values.rotate ? patternBb.height : patternBb.width;


    const activePath = activeObj.path;


    const pathLength = calculatePathLength(activePath,activeObj.calcOwnMatrix(),activeObj) ;
    const tangentialOffset = (values.tangentialOff) ;

    const numClones = Math.floor(pathLength / (patternDimension + values.spacing));
    const segmentLength = pathLength / numClones;
    console.log('distribute: values.spacing',values.spacing )

    console.log('distribute: segmentLength,pathLength,numClones',segmentLength,pathLength,numClones )
    // let allClones = [];
    for (let i = 0; i < numClones; i++) {
        const pointOnPath = getPointAtLength(activePath, tangentialOffset+ (i * (segmentLength) ), activeObj);
        console.log('distribute: pointOnPath',pointOnPath )

        const clone = fabric.util.object.clone(patternPath);
        if (clone.type === "group") {
          clone.originX = "center";
          clone.originY = "center";
          const groupBb = clone.getBoundingRect();
          clone.left = pointOnPath.x;
          clone.top = pointOnPath.y + values.normalOff;
          clone.class = "distributeCopy";
          clone.dirty = true;
          clone.setCoords();

          const totalObjs = clone._objects.length; 
          const groupCenter = clone.getCenterPoint();

          clone.forEachObject((child) => {
            
              child.left = child.left * clone.scaleX + clone.left;
              child.top = child.top * clone.scaleY + clone.top;
              child.setCoords();
          });
          
          if(values.objByPartRotate){
            if (values.groupMode === "Sequential") {
                if (i % 2 === 0) {
                    // Even positions: Hide the last object
                    clone.forEachObject((child, index) => {
                        child.visible = index !== totalObjs - 1; // All visible except the last
                    });
                } else if (i % 2 === 1) {
                    // Odd positions: Hide all except the last object
                    clone.forEachObject((child, index) => {
                        child.visible = index === totalObjs - 1; // Only the last visible
                    });
                }
            }else if (values.groupMode === "Random") {
              const randomToggle = Math.random() >= 0.5; // Random boolean
              if (randomToggle) {
                  // Hide the last object
                  clone.forEachObject((child, index) => {
                      child.visible = index !== totalObjs - 1; // All visible except the last
                  });
              } else {
                  // Hide all except the last object
                  clone.forEachObject((child, index) => {
                      child.visible = index === totalObjs - 1; // Only the last visible
                  });
              }
              }
          }
          console.log("distribute: clone positions (group) & their bb:", { x: clone.left, y: clone.top }, groupBb, groupCenter);
      }
      else{


        clone.left = pointOnPath.x;
        clone.top = pointOnPath.y ;
        clone.originX = 'center';
        clone.originY = 'center';
        clone.class = 'distributeCopy';

        console.log('distribute: clone positions: single: ',clone );

        }
        console.log('distribute: final',clone )
        if(values.mode === "Moved"){
          patternPath.cloneParent = false;

          mainCanvas.forEachObject((obj) => {
            if(obj.class === "patternPath"){
              mainCanvas.remove(obj);
            }
          })        
        }else if (values.mode === "Cloned"){
          
          clone.class = 'distributeCopy-cloned';
          patternPath.cloneParent = true
          
        }else{
          patternPath.cloneParent = false;
        }
        let tangentAngle = getTangentAngle(activePath, i * (segmentLength), activeObj);
        if (values.rotate) {
          clone.angle = 90;
        }else if(values.tangentialRotate){
          // let tangentAngle = getTangentAngle(activePath, (i * (segmentLength + values.tangentialOff)), activeObj);
        console.log('distribute: tangentAngle',tangentAngle )
          clone.angle = tangentAngle;
        }

        if(values.normalOff !== 0){
          if(values.tangentialRotate){
            const tangentRadians = (tangentAngle * Math.PI) / 180; 
            
            const normalX = Math.sin(tangentRadians); 
            const normalY = -Math.cos(tangentRadians);
            console.log('distribute: normalX, normalY, tangentRadians: ',normalX, normalY,tangentRadians )
    
            const normalOffsetX = normalX * values.normalOff;
            const normalOffsetY = normalY * values.normalOff;
            console.log('distribute: normalOffsetX, normalOffsetY: ',normalOffsetX, normalOffsetY )
            clone.left += normalOffsetX;
            clone.top += normalOffsetY;
          }else{
            clone.top += values.normalOff;
          }

        
        }


        mainCanvas.add(clone);
        mainCanvas.bringToFront(clone);
    }
    // makeClonesActive(mainCanvas)
    mainCanvas.renderAll();
}

/**
 * Helper function to calculate the length of a path
 */
function calculatePathLength(path, transformMatrix, activeObj) {
  let length = 0;
  let lastPoint = [0, 0]; // Default starting point
  let startPoint = [0, 0]; // Track the starting point for the "Z" command
  console.log('distribute: calculatePathLength: ', path, transformMatrix, activeObj);

  for (let i = 0; i < path.length; i++) {
    const [command, ...coords] = path[i];
    const transformed = transformPoint(coords, transformMatrix, activeObj);
    console.log('distribute: coords, trnasformedPoint: ', coords, transformed);
    if (command === "M") {
      // Move command, update start and last point
      startPoint = transformed;
      lastPoint = transformed;
    } else if (command === "L") {
      // Line segment
      if (lastPoint[0] !== undefined && lastPoint[1] !== undefined && transformed[0] !== undefined && transformed[1] !== undefined) {
        length += Math.sqrt((transformed[0] - lastPoint[0]) ** 2 + (transformed[1] - lastPoint[1]) ** 2);
      }
      lastPoint = transformed;
    } 
    else if (command === "C") {
      // Cubic Bézier curve
      const [cp1x, cp1y, cp2x, cp2y, x2, y2] = coords;
      const [x1, y1] = lastPoint; // Use the last point as the starting point

      if (
        x1 !== undefined &&
        y1 !== undefined &&
        cp1x !== undefined &&
        cp1y !== undefined &&
        cp2x !== undefined &&
        cp2y !== undefined &&
        x2 !== undefined &&
        y2 !== undefined
      ) {
        // Transform all control points and end point
        const [transCp1x, transCp1y] = transformPoint([cp1x, cp1y], transformMatrix, activeObj);
        const [transCp2x, transCp2y] = transformPoint([cp2x, cp2y], transformMatrix, activeObj);
        const [transX2, transY2] = transformPoint([x2, y2], transformMatrix, activeObj);

        // Create a Bezier curve using Bezier.js
        const curve = new Bezier(
          x1, y1,
          transCp1x, transCp1y,
          transCp2x, transCp2y,
          transX2, transY2
        );

        // Add the length of the cubic Bézier curve
        length += curve.length();
      }

      // Update last point to the end of the cubic curve
      lastPoint = transformPoint([x2, y2], transformMatrix, activeObj);
    }
    else if (command === "Z") {
      // Close the path: connect last point to the starting point
      if (lastPoint[0] !== undefined && lastPoint[1] !== undefined && startPoint[0] !== undefined && startPoint[1] !== undefined) {
        length += Math.sqrt((startPoint[0] - lastPoint[0]) ** 2 + (startPoint[1] - lastPoint[1]) ** 2);
      }
    }
  }

  return length;
}


/**
 * Transform a point using the transformation matrix.
 */
function transformPoint(coords, matrix,activeObj) {
  if (!coords || coords.length < 2) return [undefined, undefined];
  const [x, y] = coords;
  const point = new fabric.Point(x-activeObj.pathOffset.x, y-activeObj.pathOffset.y);
  const transformed = fabric.util.transformPoint(point, matrix);
  return [transformed.x, transformed.y];
}




function getPointAtLength(path, targetLength, activeObj) {
  let accumulated = 0;
  const transformMatrix = activeObj.calcTransformMatrix();
  let lastPoint = [0, 0];
  let startPoint = [0, 0];

  for (let i = 0; i < path.length; i++) {
    const [command, ...coords] = path[i];
    let transformedLastPoint;
    let transformedCoords;
    if(command !== "Z"){
      transformedLastPoint = transformPoint(lastPoint, transformMatrix, activeObj);
      transformedCoords = transformPoint(coords, transformMatrix, activeObj);  
    }
    else{
      transformedLastPoint = transformPoint(lastPoint, transformMatrix, activeObj);
      transformedCoords = startPoint;
    }


    if (command === "M") {
      // Move command: set starting point
      startPoint = transformedCoords;
      lastPoint = coords;
    } else if (command === "L" || command === "Z") {
      // Line segment
      const [x1, y1] = transformedLastPoint;
      const [x2, y2] = transformedCoords;
      const segmentLength = Math.sqrt((x2 - x1) ** 2 + (y2 - y1) ** 2);

      if (accumulated + segmentLength >= targetLength) {
        const ratio = (targetLength - accumulated) / segmentLength;
        return {
          x: x1 + ratio * (x2 - x1),
          y: y1 + ratio * (y2 - y1),
        };
      }

      accumulated += segmentLength;
      lastPoint = coords;
    } else if (command === "C") {
      // Cubic Bézier curve
      const [cp1x, cp1y, cp2x, cp2y, x2, y2] = coords;
      const [x1, y1] = transformedLastPoint;

      // Transform all control points and the endpoint
      const [transCp1x, transCp1y] = transformPoint([cp1x, cp1y], transformMatrix, activeObj);
      const [transCp2x, transCp2y] = transformPoint([cp2x, cp2y], transformMatrix, activeObj);
      const [transX2, transY2] = transformPoint([x2, y2], transformMatrix, activeObj);

      // Create a cubic Bézier curve using Bezier.js
      const curve = new Bezier(x1, y1, transCp1x, transCp1y, transCp2x, transCp2y, transX2, transY2);

      const segmentLength = curve.length();

      if (accumulated + segmentLength >= targetLength) {
        const ratio = (targetLength - accumulated) / segmentLength;

        // Use Bezier.js to find the point at the specified ratio
        const { x, y } = curve.get(ratio);
        return { x, y };
      }

      accumulated += segmentLength;
      lastPoint = coords.slice(-2); // Update last point to the end of the cubic curve
    }
  }

  // Return the last transformed point if targetLength exceeds the path length
  const lastTransformedPoint = transformPoint(lastPoint, transformMatrix, activeObj);
  return {
    x: lastTransformedPoint[0],
    y: lastTransformedPoint[1],
  };
}





/**
 * Helper function to calculate the tangent angle at a specific length along a path
 */
function getTangentAngle(path, targetLength, activeObj) {
  let accumulated = 0;
  const transformMatrix = activeObj.calcTransformMatrix();
  let lastPoint = [0, 0];
  let startPoint = [0, 0];

  for (let i = 0; i < path.length; i++) {
    const [command, ...coords] = path[i];
    let transformedLastPoint;
    let transformedCoords;
    if(command !== "Z"){
      transformedLastPoint = transformPoint(lastPoint, transformMatrix, activeObj);
      transformedCoords = transformPoint(coords, transformMatrix, activeObj);  
    }
    else{
      transformedLastPoint = transformPoint(lastPoint, transformMatrix, activeObj);
      transformedCoords = startPoint;
    }

    if (command === "M") {
      // Move command: set starting point
      startPoint = transformedCoords;
      lastPoint = coords;
    } else if (command === "L" || command === "Z") {
      // Line segment or closure line
      const [x1, y1] = transformedLastPoint;
      const [x2, y2] = transformedCoords;
      const segmentLength = Math.sqrt((x2 - x1) ** 2 + (y2 - y1) ** 2);

      if (accumulated + segmentLength >= targetLength) {
        // Compute the tangent angle of the line segment
        return (Math.atan2(y2 - y1, x2 - x1) * 180) / Math.PI;
      }

      accumulated += segmentLength;
      lastPoint = coords;
    } else if (command === "C") {
      // Cubic Bézier curve
      const [cp1x, cp1y, cp2x, cp2y, x2, y2] = coords;
      const [x1, y1] = transformedLastPoint;

      // Transform all control points and the endpoint
      const [transCp1x, transCp1y] = transformPoint([cp1x, cp1y], transformMatrix, activeObj);
      const [transCp2x, transCp2y] = transformPoint([cp2x, cp2y], transformMatrix, activeObj);
      const [transX2, transY2] = transformPoint([x2, y2], transformMatrix, activeObj);

      // Create a cubic Bézier curve using Bezier.js
      const curve = new Bezier(x1, y1, transCp1x, transCp1y, transCp2x, transCp2y, transX2, transY2);

      const segmentLength = curve.length();

      if (accumulated + segmentLength >= targetLength) {
        const ratio = (targetLength - accumulated) / segmentLength;

        // Get the tangent vector at the specified ratio
        const tangent = curve.derivative(ratio);

        // Compute the tangent angle in degrees
        return (Math.atan2(tangent.y, tangent.x) * 180) / Math.PI;
      }

      accumulated += segmentLength;
      lastPoint = coords.slice(-2); // Update last point to the end of the cubic curve
    }
  }

  // Return 0 if targetLength exceeds the path length
  return 0;
}





  
  const renderPopoverContent = () => {
    if (selectedFeature) {
      const feature = path.find(item => item.label === selectedFeature);
      if(selectedFeature === "Pattern Along Path"){
        return (
          <div style={{ height: "500px", width: '300px' }}>
            <div style={{ display: "flex", justifyContent: 'space-between', alignItems: "center", marginRight: "10px", marginLeft: "10px" }}>
              <IconButton edge="start" sx={{ borderRadius: 0 }} onClick={handleBackButtonClick}>
                <ArrowBack />
              </IconButton>
              <Typography sx={{ m: 0, fontSize: 18, color:'#f0f2f7', fontWeight: 800 }}>{selectedFeature}</Typography>
              <IconButton edge="end" sx={{ borderRadius: 0 }} onClick={handlePopoverClose}>
                <Clear />
              </IconButton>
            </div>
            <div style={{ display: "flex", rowGap: 18, margin: "10px", flexWrap: "wrap", justifyContent:"center" }}>
            <div style={{display:'flex',width:'100%',alignItems:'center', justifyContent:"space-between"}}>
            <Typography sx={{fontSize:"14px"}}>Original copy will be:</Typography>
              <Select size='small' value={values.mode} onChange={handleChange} name='mode' sx={{fontSize:"14px", width:'150px'}}>
                <MenuItem value="Moved">Moved</MenuItem>
                <MenuItem value="Duplicated">Duplicated</MenuItem>
                <MenuItem value="Cloned">Cloned</MenuItem>
              </Select>
            </div>
                <div style={{display:'flex',width:'100%',alignItems:'center', justifyContent:"space-between"}}>
            <Typography sx={{fontSize:"14px"}}>Spacing</Typography>

              <TextField size='small' sx={{width:'150px'}}  value={values.spacing} onChange={handleChange} name='spacing' type="number"/>
              </div>
              <div style={{display:'flex',width:'100%',alignItems:'center', justifyContent:"space-between"}}>
            <Typography sx={{fontSize:"14px"}}>Follow Path Orientation:</Typography>
              <Checkbox value={values.tangentialRotate} checked={values.tangentialRotate}  name='tangentialRotate' onChange={handleChange}/>
              {/* <TextField size='small' sx={{width:'150px'}} value={values.spacing} onChange={handleChange} name='spacing' type="number"/> */}
              </div>
              <div style={{display:'flex',width:'100%',alignItems:'center', justifyContent:"space-between"}}>
            <Typography sx={{fontSize:"14px"}}>Rotate Copies By 90°</Typography>
              <Checkbox value={values.rotate} checked={values.rotate}  name='rotate' onChange={handleChange}/>
              {/* <TextField size='small' sx={{width:'150px'}} value={values.spacing} onChange={handleChange} name='spacing' type="number"/> */}
              </div>
              
                <div style={{display:'flex',width:'100%',alignItems:'center', justifyContent:"space-between"}}>
            <Typography sx={{fontSize:"14px"}}>Normal Offset</Typography>

              <TextField size='small' sx={{width:'150px'}} value={values.normalOff} onChange={handleChange} name='normalOff' type="number"/>
              </div>
                <div style={{display:'flex',width:'100%',alignItems:'center', justifyContent:"space-between"}}>
            <Typography sx={{fontSize:"14px"}}>Tangential Offset</Typography>

              <TextField size='small' sx={{width:'150px'}} value={values.tangentialOff} onChange={handleChange} name='tangentialOff' type="number"/>
              </div>
              <div style={{display:'flex',width:'100%',alignItems:'center', justifyContent:"space-between"}}>
            <Typography sx={{fontSize:"14px"}}>If Pattern is Group, handle each Individual Object:</Typography>
              <Checkbox value={values.objByPartRotate} checked={values.objByPartRotate}  name='objByPartRotate' onChange={handleChange}/>
              {/* <TextField size='small' sx={{width:'150px'}} value={values.spacing} onChange={handleChange} name='spacing' type="number"/> */}
              </div>

              {values.objByPartRotate &&
              <>
              <div style={{display:'flex',width:'100%',alignItems:'center', justifyContent:"space-between"}}>
            <Typography sx={{fontSize:"14px"}}>Group Members Generation:</Typography>
              <Select size='small' value={values.groupMode} onChange={handleChange} name='groupMode' sx={{fontSize:"14px", width:'150px'}}>
                <MenuItem value="Random">Random</MenuItem>
                <MenuItem value="Sequential">Sequential</MenuItem>
              </Select>
            </div>
            </>}
              <div style={{display:'flex',width:'100%',alignItems:'center', justifyContent:"space-between"}}>

              <Button variant='contained' onClick={handleCopyShape}>
                Copy
              </Button>
              <Button onClick={distributeAlongPath} variant='contained' disabled={!mainCanvas.patternPath}>
                Apply
              </Button>
              </div>
              
            </div>
          </div>
        );
      }
      if(selectedFeature === "Mirror Rotations"){
        return  <CustomRotateCopiesPopoverContent
        handlePathRotateCopies={handlePathRotateCopies}
        rotationSettings={rotationSettings}
        updateRotationSettings={updateRotationSettings}
        handlePopoverClose={handlePopoverClose}
        handleBackButtonClick={handleBackButtonClick}
        applyColorToSelectedCopy={applyColorToSelectedCopy}
        setSelectedColorForRotationCopy={setSelectedColorForRotationCopy}
        selectedColorForRotationCopy={selectedColorForRotationCopy}
        selectedStrokeForRotationCopy={selectedStrokeForRotationCopy}
        setSelectedStrokeForRotationCopy={setSelectedStrokeForRotationCopy}
        color={color}
        handleSpraying={handleSpraying}
        opacityObj={opacityObj}
        changeOpacityOfObj={changeOpacityOfObj}
      />
      }
      return (
        <div style={{ height: "500px", width: '300px' }}>
          <div style={{ display: "flex", justifyContent: 'space-between', alignItems: "center", marginRight: "10px", marginLeft: "10px" }}>
            <IconButton edge="start" sx={{ borderRadius: 0 }} onClick={handleBackButtonClick}>
              <ArrowBack />
            </IconButton>
            <Typography sx={{ m: 0, fontSize: 18, color:'#f0f2f7', fontWeight: 800 }}>{selectedFeature}</Typography>
            <IconButton edge="end" sx={{ borderRadius: 0 }} onClick={handlePopoverClose}>
              <Clear />
            </IconButton>
          </div>
          <div style={{ display: "flex", columnGap: 8, margin: "10px", flexWrap: "wrap" }}>
            {feature?.children?.map((obj, index) => (
              <Tooltip key={index} title={obj.label}>
                <PopoverListButtons onClick={obj.onClick}>
                  {obj.icon}
                </PopoverListButtons>
              </Tooltip>
            ))}
          </div>
        </div>
      );
    }

    return (
      <div style={{ height: "500px", width: '300px' }}>
        <div style={{ display: "flex", justifyContent: 'space-between', alignItems: "center", marginRight: "10px" }}>
          <Typography sx={{ m: 0, pl: 2, fontSize: 18, color:'#f0f2f7', fontWeight: 800 }}>Path</Typography>
          <IconButton edge="end" sx={{ borderRadius: 0 }} onClick={handlePopoverClose}>
            <Clear />
          </IconButton>
        </div>
        <div style={{ display: "flex",flexDirection:"column", gap: 8, margin: "10px"}}>
          {path.map((obj, index) => (
            <Button onClick={obj.onClick} variant='outlined' sx={{alignItems:"center",justifyContent:"space-between", display:"flex"}}>
            <div style={{display:"flex",alignItems:"center"}}>
              {obj.icon}
            </div>
            <div style={{display:"flex",alignItems:"center"}}>
              {obj.label}
            </div>
          </Button>
          ))}
        </div>
      </div>
    );
  };

  return (
    <>
      {renderPopoverContent()}
    </>
  );
};

export default CustomPathPopoverContent;
