import React, { useEffect, useState } from 'react';
import {
  TabContext,
  TabPanel
} from '@mui/lab';
import { fabric } from 'fabric';
import ColorPicker from 'react-best-gradient-color-picker'
import {
  Box,
  Typography,
  IconButton,
  Tabs,
  Tab,
  TextField,
  MenuItem,
  Button,
  Tooltip,
  Slider,
  InputAdornment
} from '@mui/material';
import { useColor } from 'react-color-palette';
import 'react-color-palette/css';
import { ArrowBack, BlurCircular, BlurCircularOutlined, Clear } from '@mui/icons-material';
import { PopoverListButtons, StyledSubMenu, StyledSubMenuContainer, StyledSubMenuTitleContainer } from '../../../Utils/styledComps';
import ReactGPicker from 'react-gcolor-picker';
import convert from 'color-convert';
import { FaLinesLeaning } from 'react-icons/fa6';
import { gradientToCssString } from '../../Tools/colorTools';
const CustomColorPopoverContent = ({
  selectedFeature,
  currentPopover,
  fillColor,
  onSetColor,
  setSelectedStrokeColor,
  setSelectedFeature,
  selectedStrokeColor,
  color,
  handlePopoverClose,
  changeOpacityOfObj,
  opacityObj
}) => {
  const [colorMode, setColorMode] = useState('rgba');  // Default to RGBA
  const [fillValue, setFillValue] = useState();  // RGBA color state]
  const [rgbValues, setRgbValues] = useState({ r: 255, g: 0, b: 0 });
  const [cmykValue, setCmykValue] = useState({ c: 255, m: 0, y: 0, k: 0 });
  const [prevColor, setPrevColor] = useState(null);
  const [prevStrokeColor, setPrevStrokeColor] = useState(null);


  const marks = [
    { value: 0, label: '0%' },
    { value: 0.2, label: '20%' },
    { value: 0.4, label: '40%' },
    { value: 0.6, label: '60%' },
    { value: 0.8, label: '80%' },
    { value: 1, label: '100%' },
  ];

  useEffect(() => {
    if (selectedFeature !== '')
      setSelectedFeature('Fill Color')
  }, [])
  const renderPopoverContent = () => {
    const changeStroke = (e) => {
      setPrevStrokeColor(selectedStrokeColor);
      setSelectedStrokeColor(e);
     
      console.log('setSelectedStrokeColor: ', e);
    };

    const changeFill = (e) => {
      setPrevColor(fillValue);

      setPrevColor(fillValue);
      onSetColor(e);

      console.log('onSetColor: ', e);
    };   
    const strokeValue = typeof selectedStrokeColor === 'object'
      ? gradientToCssString(selectedStrokeColor)
      : selectedStrokeColor
    const fillValue = typeof fillColor === 'object'
      ? gradientToCssString(fillColor)
      : fillColor
    return (
      <TabContext value={selectedFeature}>
        <Box sx={{ width: '340px', overflow: "hidden" }}>
          <div style={{ display: "flex", justifyContent: 'space-between', alignItems: "center", marginRight: "10px", paddingLeft: "10px" }}>
            <Typography sx={{ m: 0, pl: 2, fontSize: 18, color: '#f0f2f7', fontWeight: 800 }}>
              {currentPopover ? currentPopover.charAt(0).toUpperCase() + currentPopover.slice(1) : null}
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              
              <IconButton sx={{ borderRadius: 0 }} onClick={handlePopoverClose}>
                <Clear />
              </IconButton>
            </Box>
          </div>
          <Tabs
            value={selectedFeature}
            onChange={(event, newValue) => setSelectedFeature(newValue)}
            variant="scrollable"
            sx={{ borderBottom: 1, p: 0, borderColor: 'divider' }}
          >
            {color.map((item) => (
              <Tooltip key={item.label} arrow title={item.label}>
                <Tab
                  sx={{ p: 0, "&:hover, &:active, &:focus": { color: "#00F3FF", borderBottom: "1px solid", borderColor: "#00F3FF" } }}
                  icon={item.icon}
                  value={item.label}
                />
              </Tooltip>
            ))}
          </Tabs>

          {color.map((item) => (
            <TabPanel key={item.label} value={item.label} sx={{ overflow: "auto", height: "430px", padding: "10px" }}>
              {item.label === 'Fill Color' && (
                <StyledSubMenuContainer>
                  <StyledSubMenuTitleContainer>
                    <StyledSubMenu>Fill Colour</StyledSubMenu>
                  </StyledSubMenuTitleContainer>
                  <StyledSubMenuTitleContainer sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}>
                    <StyledSubMenu>
                      Previous Colour
                      <div style={{
                        width: '100px',
                        height: '30px',
                        border: '1px solid black',
                        background: prevColor?.includes('gradient') ? prevColor : undefined,
                        backgroundColor: !prevColor?.includes('gradient') ? prevColor : undefined,
                        marginTop: '5px'
                      }} />
                    </StyledSubMenu>

                    <StyledSubMenu>
                      Current Colour
                      <div style={{
                        width: '100px',
                        height: '30px',
                        border: '1px solid black',
                        background: fillValue?.includes('gradient') ? fillValue : undefined,
                        backgroundColor: !fillValue?.includes('gradient') ? fillValue : undefined,
                        marginTop: '5px'
                      }} />
                    </StyledSubMenu>
                  </StyledSubMenuTitleContainer>


                  <ColorPicker hideEyeDrop style={{ borderRadius: '10px', padding: 1 }} value={fillValue} onChange={changeFill} />
                </StyledSubMenuContainer>
              )}

              {item.label === 'Stroke Color' && (
                <StyledSubMenuContainer>
                  <StyledSubMenuTitleContainer>
                    <StyledSubMenu>Stroke Colour</StyledSubMenu>
                  </StyledSubMenuTitleContainer>
                  <StyledSubMenuTitleContainer sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}>
                    <StyledSubMenu>
                      Previous Colour
                      <div style={{
                        width: '100px',
                        height: '30px',
                        border: '1px solid black',
                        background: prevStrokeColor?.includes('gradient') ? prevStrokeColor : undefined,
                        backgroundColor: !prevStrokeColor?.includes('gradient') ? prevStrokeColor : undefined,
                        marginTop: '5px'
                      }} />
                    </StyledSubMenu>

                    <StyledSubMenu>
                      Current Colour
                      <div style={{
                        width: '100px',
                        height: '30px',
                        border: '1px solid black',
                        background: strokeValue?.includes('gradient') ? strokeValue : undefined,
                        backgroundColor: !strokeValue?.includes('gradient') ? strokeValue : undefined,
                        marginTop: '5px'
                      }} />
                    </StyledSubMenu>
                  </StyledSubMenuTitleContainer>
                  <ColorPicker hideEyeDrop style={{ borderRadius: '10px', padding: 1 }} value={strokeValue} onChange={(e) => { changeStroke(e) }} />
                </StyledSubMenuContainer>

              )}

              {item.label === 'Opacity' && (
                <StyledSubMenuContainer sx={{justifyContent:'center',display:'flex'}}>
                <Typography align='center'>Please Select a shape to change its opacity.</Typography>

                <Slider
                  sx={{
                    marginTop: '20px',
                    maxWidth: '220px',
                    '& .MuiSlider-mark': {
                      backgroundColor: 'blue',
                      height: '8px',
                      width: '2px',
                      '&.MuiSlider-markActive': {
                        opacity: 1,
                        backgroundColor: 'navyblue',
                      },
                    },
                    '& .MuiSlider-markLabel': {
                      color: 'black',
                    },
                  }}
                  value={opacityObj}
                  onChange={(e, newValue) => changeOpacityOfObj(newValue)}
                  step={0.01}
                  min={0}
                  max={1}
                  valueLabelDisplay="auto"
                  marks={marks}
                />
                </StyledSubMenuContainer>
              )}




            </TabPanel>
          ))}
        </Box>
      </TabContext>
    );
  };

  return (
    <>
      {renderPopoverContent()}
    </>
  );
};

export default CustomColorPopoverContent;
