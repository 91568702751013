export const handleSpraying = (spraySelectRef, setSprayObj,setSpraySelect,sprayObjRef,activeObj) => {
    if (spraySelectRef.current) {
      setSprayObj(null);
      setSpraySelect(false);
      sprayObjRef.current = null;
      spraySelectRef.current = false;
    } else if (activeObj?.[0]) {
      const selectedObj = activeObj[0];
      setSprayObj(selectedObj);
      setSpraySelect(true);
      sprayObjRef.current = selectedObj;
      spraySelectRef.current = true;
    }

    console.log("handleSpraying:", spraySelectRef.current, sprayObjRef.current);
  };

export  const gradientToCssString = (gradient) => {
  const coords = gradient?.coords;
  if(coords){
  const angle = Math.atan2(coords.y2 - coords.y1, coords.x2 - coords.x1) * (180 / Math.PI);
  const stops = gradient.colorStops.map((stop) => `${stop.color} ${stop.offset * 100}%`).join(', ');

  return `linear-gradient(${angle}deg, ${stops})`;}
};